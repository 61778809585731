var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "body-class": "pb-50"
    }
  }, [_c('h6', [_vm._v("Orders")]), _c('h2', {
    staticClass: "font-weight-bolder mb-1"
  }, [_vm._v(" 2,76k ")]), _c('vue-apex-charts', {
    attrs: {
      "height": "70",
      "options": _vm.statisticsOrder.chartOptions,
      "series": _vm.statisticsOrder.series
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }